import Axios, { AxiosInstance } from 'axios'
import qs from 'qs'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const apiDomain = publicRuntimeConfig.apiDomain

export default class Ajax {
  axiosInstance: AxiosInstance // add this line

  constructor(options: any) {
    this.axiosInstance = Axios.create(options)
  }

  /**
   * 内部方法: 根据api名称匹配完整路径··
   * @param {*} path
   */
  __getFullUrl(apiName: string, path: string) {
    if (apiName && (!path || !path.length)) {
      throw new Error(`接口路径名称不可以为空`)
    }
    if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
      // 开发环境下且是客户端发起，使用/proxy-api标识来做接口代理，避免开发环境下跨域
      return `/proxy-api${path}`
    } else {
      return `${apiDomain}${path}`
    }
  }

  /**
   * Get请求
   * @param {*} apiName
   * @param {*} url
   * @param {*} params
   * @returns
   */
  get(apiName: string, url: string, params: object) {
    return this.axiosInstance.get(this.__getFullUrl(apiName, url), {
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }

  /**
   * POST请求
   * @param {*} apiName
   * @param {*} url
   * @param {*} params
   * @returns
   */
  post(apiName: string, url: string, data?: object) {
    return this.axiosInstance.post(this.__getFullUrl(apiName, url), data)
  }

  /**
   * PUT请求
   * @param {*} apiName
   * @param {*} url
   * @param {*} params
   * @returns
   */
  put(apiName: string, url: string, data: string) {
    return this.axiosInstance.put(this.__getFullUrl(apiName, url), data)
  }

  /**
   * DLETE请求
   * @param {*} apiName
   * @param {*} url
   * @param {*} config  query方式: {params:xxx }, body方式:{data:xxx}
   * @returns
   */
  delete(apiName: string, url: string, config: any) {
    return this.axiosInstance.delete(this.__getFullUrl(apiName, url), config)
  }
}
